import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

Vue.prototype.$dev = Vue.config.devtools;

Vue.prototype.$siteUrl = "https://naming-tool-web.pages.dev";

if (Vue.prototype.$dev) {
  Vue.prototype.$siteUrl = "http://localhost:8080";
}

const store = Vue.observable({
  isLoggedIn: false,
  notifications: [],
  addNotification(notification) {
    store.notifications.push(notification);
  },
  getAndRemoveNotification() {
    return store.notifications.shift();
  },
});

Vue.prototype.$globalState = store;

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
