import { supabase } from "../supabase/supabase";

async function getLocations() {
  const locations = await supabase
    .from("location")
    .select()
    .order("short", { ascending: true });
  if (locations.error) {
    console.log(locations.error);
  } else {
    return locations.data;
  }
}

async function getRooms() {
  const rooms = await supabase
    .from("room")
    .select()
    .order("short", { ascending: true });
  if (rooms.error) {
    console.log(rooms.error);
  } else {
    return rooms.data;
  }
}

async function getTypes() {
  const types = await supabase
    .from("type")
    .select()
    .order("short", { ascending: true });
  if (types.error) {
    console.log(types.error);
  } else {
    return types.data;
  }
}

export { getLocations, getRooms, getTypes };
