<template>
  <div>
    <v-row class="mt-10">
      <v-col class="d-flex justify-center">
        <h1>Login</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="hidden-sm-and-down"></v-col>
      <v-col md="4">
        <v-card color="primary">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  type="email"
                  label="E-Mail"
                  v-model="email"
                  color="secondary"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  type="password"
                  label="Passwort"
                  v-model="password"
                  color="secondary"
                  @keyup.enter="handleLogin"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <span
                  class="secondary--text text-caption text-decoration-underline"
                  style="cursor:pointer"
                  @click="forgotPassword"
                  >Passwort vergessen?</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn color="secondary" @click="handleLogin">Login</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    handleLogin: async function() {
      await supabase.auth.signIn({
        email: this.email,
        password: this.password,
      });
      if (this.$route.query.redirect) {
        this.$router.push({ name: this.$route.query.redirect });
      } else {
        this.$router.push({ name: "Create" });
      }
    },
    forgotPassword: async function() {
      if (this.email) {
        let response = await supabase.auth.api.resetPasswordForEmail(
          this.email
        );
        if (response.error) {
          this.$globalState.addNotification({
            duration: 4000,
            type: "info",
            headline: "",
            message: "Unbekannte E-Mail-Adresse",
          });
        } else {
          this.$globalState.addNotification({
            duration: 4000,
            type: "info",
            headline: "",
            message:
              "Ein Link zum Zurücksetzen des Passworts wurde an die angegebene E-Mail-Adresse gesendet.",
          });
        }
        console.log(response);
      } else {
        this.$globalState.addNotification({
          duration: 4000,
          type: "info",
          headline: "",
          message: "Bitte geben Sie Ihre E-Mail Adresse ein.",
        });
      }
    },
  },
};
</script>
<style scoped></style>
