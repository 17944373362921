import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/lib/services/goto";

import { supabase } from "../plugins/supabase/supabase";
import { getParameterByName } from "../plugins/supabase/helpers";

import Create from "../views/Create.vue";
import Resolve from "../views/Resolve.vue";
import Data from "../views/Data.vue";
import Concept from "../views/Concept.vue";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Create",
    component: Create,
    meta: { authRequired: false },
  },
  {
    path: "/resolve",
    name: "Resolve",
    component: Resolve,
    meta: { authRequired: false },
  },
  {
    path: "/data",
    name: "Data",
    component: Data,
    meta: { authRequired: true },
  },
  {
    path: "/concept",
    name: "Concept",
    component: Concept,
    meta: { authRequired: false },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { authRequired: false },
  },
  {
    path: "/resetPassword",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { authRequired: false },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;
    let options = {
      offset: 75,
    };

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo, options);
  },
});

router.beforeEach(async (to, from, next) => {
  // TODO when invited by link logout after reset password was sent
  let toUrl = location.origin + to.fullPath;
  const requestType = getParameterByName("type", toUrl);

  if (supabase.auth.session()) {
    Vue.prototype.$globalState.isLoggedIn = true;
  } else {
    Vue.prototype.$globalState.isLoggedIn = false;
  }

  if (requestType === "email_change") {
    const accessToken = getParameterByName("access_token", toUrl);
    supabase.auth.setAuth(accessToken);
    if (supabase.auth.session()) {
      next({
        name: "Create",
        query: { message: "E-Mail changed successfully!" },
      });
    } else {
      next({ name: "Create", query: { message: "Failed changing E-Mail" } });
    }
  } else if (requestType === "signup") {
    const accessToken = getParameterByName("access_token", toUrl);
    supabase.auth.setAuth(accessToken);
    if (supabase.auth.session()) {
      next({
        name: "Create",
        query: { message: "Successfully confirmed Sign-Up!" },
      });
    } else {
      next({ name: "Login", query: { message: "Failed confirming Sign-Up" } });
    }
  } else if (requestType === "recovery") {
    const accessToken = getParameterByName("access_token", toUrl);
    next({ name: "ResetPassword", query: { token: accessToken } });
  } else {
    if (to.meta.authRequired) {
      if (supabase.auth.session()) {
        next();
      } else {
        next({ name: "Login", query: { redirect: to.name } });
      }
    } else {
      if (to.name === "Login") {
        if (supabase.auth.session()) {
          next({ name: "Data" });
        } else {
          next();
        }
      } else if (to.name === "ResetPassword") {
        if (to.query && to.query.token) {
          next();
        } else if (supabase.auth.session()) {
          next({
            name: "Create",
            query: {
              message:
                "You cannot access this site without a Password-Reset-Token!",
            },
          });
        } else {
          next({
            name: "Login",
            query: {
              message:
                "You cannot access this site without a Password-Reset-Token!",
            },
          });
        }
      } else {
        next();
      }
    }
  }
});

export default router;
