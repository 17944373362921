<template>
  <div>
    <v-row class="mt-10">
      <v-col class="d-flex justify-center">
        <h1>Stammdatenverwaltung</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="2" class="hidden-sm-and-down"></v-col>
      <v-col md="8">
        <createDataPanels />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="hidden-sm-and-down"></v-col>
      <v-col md="8">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="hidden-sm-and-down"></v-col>
      <v-col md="8">
        <editDelDataPanels />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import createDataPanels from "@/components/createDataPanels.vue";
import editDelDataPanels from "@/components/editDelDataPanels.vue";
export default {
  name: "Data",
  components: {
    createDataPanels,
    editDelDataPanels,
  },

  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
  },
};
</script>
<style scoped></style>
