<template>
  <div>
    <v-row class="mt-10">
      <v-col class="d-flex justify-center">
        <h1>Erstellen einer Beschriftung</h1>
      </v-col>
    </v-row>
    <!-- DESKTOP-View -->
    <v-row class="hidden-sm-and-down">
      <v-col>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-autocomplete
              label="Standort"
              :items="locations"
              item-value="short"
              item-text="alias"
              v-model="label.location"
              color="secondary"
              item-color="secondary"
              @change="loadLocationRooms"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="label.location" class="d-flex justify-center">
            <v-autocomplete
              label="Raum"
              :items="locationRooms"
              item-value="short"
              item-text="long"
              v-model="label.room"
              color="secondary"
              item-color="secondary"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="label.room" class="d-flex justify-center">
            <v-autocomplete
              label="Art"
              :items="types"
              item-value="short"
              item-text="long"
              v-model="label.type"
              color="secondary"
              item-color="secondary"
              @change="loadSelectedType"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="selectedType && selectedType.requirements.typeNumber"
            class="d-flex justify-center"
          >
            <v-text-field
              :label="getArtLong() + ' (Art-Nr.)'"
              type="number"
              v-model="label.typeNumber"
              color="secondary"
              @keyup.enter="createLabel"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="selectedType && selectedType.requirements.shelfNumber"
            class="d-flex justify-center"
          >
            <v-text-field
              label="Schrank Nr."
              type="number"
              v-model="label.shelfNumber"
              color="secondary"
              @keyup.enter="createLabel"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="selectedType && selectedType.requirements.port1"
            class="d-flex justify-center"
          >
            <v-text-field
              label="Port 1"
              type="number"
              v-model="label.port1"
              color="secondary"
              @keyup.enter="createLabel"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="selectedType && selectedType.requirements.port2"
            class="d-flex justify-center"
          >
            <v-text-field
              label="Port 2"
              type="number"
              v-model="label.port2"
              color="secondary"
              @keyup.enter="createLabel"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-btn
          color="secondary"
          fab
          @click="createLabel"
          :loading="loadingCreate"
        >
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-text-field
          label="Beschriftung"
          readonly
          v-model="createdLabel"
          color="secondary"
          class="mr-8"
          :loading="loadingCreate"
        >
        </v-text-field>
        <div class="pr-4">
          <v-btn icon v-clipboard:copy="createdLabel">
            <v-icon color="secondary">mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- MOBILE-View -->
    <div class="hidden-md-and-up">
      <v-row>
        <v-col class="px-10">
          <v-row>
            <v-col class="d-flex justify-center">
              <v-autocomplete
                label="Standort"
                :items="locations"
                item-value="short"
                item-text="alias"
                v-model="label.location"
                color="secondary"
                item-color="secondary"
                @change="loadLocationRooms"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="label.location" class="d-flex justify-center">
              <v-autocomplete
                label="Raum"
                :items="locationRooms"
                item-value="short"
                item-text="long"
                v-model="label.room"
                color="secondary"
                item-color="secondary"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="label.room" class="d-flex justify-center">
              <v-autocomplete
                label="Art"
                :items="types"
                item-value="short"
                item-text="long"
                v-model="label.type"
                color="secondary"
                item-color="secondary"
                @change="loadSelectedType"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="selectedType && selectedType.requirements.typeNumber"
              class="d-flex justify-center"
            >
              <v-text-field
                :label="getArtLong() + ' (Art-Nr.)'"
                type="number"
                v-model="label.typeNumber"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="selectedType && selectedType.requirements.shelfNumber"
              class="d-flex justify-center"
            >
              <v-text-field
                label="Schrank Nr."
                type="number"
                v-model="label.shelfNumber"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="selectedType && selectedType.requirements.port1"
              class="d-flex justify-center"
            >
              <v-text-field
                label="Port 1"
                type="number"
                v-model="label.port1"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="selectedType && selectedType.requirements.port2"
              class="d-flex justify-center"
            >
              <v-text-field
                label="Port 2"
                type="number"
                v-model="label.port2"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <v-btn
            color="secondary"
            fab
            @click="createLabel"
            :loading="loadingCreate"
          >
            <v-icon>mdi-arrow-down-bold</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center align-center px-10">
          <v-text-field
            label="Beschriftung"
            readonly
            v-model="createdLabel"
            color="secondary"
            class="mr-8"
            :loading="loadingCreate"
          >
          </v-text-field>
          <div class="pr-4">
            <v-btn icon v-clipboard:copy="createdLabel">
              <v-icon color="secondary">mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { getLocations, getRooms, getTypes } from "@/plugins/vuetify/helpers.js";
export default {
  name: "Create",
  data() {
    return {
      locations: [],
      rooms: [],
      types: [],
      locationRooms: [],
      label: {
        location: "",
        room: "",
        type: "",
        typeNumber: "",
        shelfNumber: "",
        port1: "",
        port2: "",
      },
      createdLabel: "",
      selectedType: null,
      loadingCreate: false,
    };
  },
  async mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
    this.locations = await getLocations();
    this.rooms = await getRooms();
    this.types = await getTypes();
  },
  methods: {
    loadLocationRooms: async function() {
      let locationId = this.getLocationId(this.label.location);
      let locationRooms = this.rooms.filter(
        (room) => room.location_id === locationId
      );
      this.locationRooms = locationRooms;
    },
    getLocationId: function(short) {
      //get id from item in locations that equals short
      let location = this.locations.find(
        (location) => location.short === short
      );
      return location.id;
    },
    getArtLong: function() {
      if (this.label.type) {
        let type = this.types.find((type) => type.short === this.label.type);
        return type.long;
      }
      return "";
    },
    loadSelectedType: function() {
      this.selectedType = this.types.find(
        (type) => type.short === this.label.type
      );
      console.log(this.selectedType);
    },
    createLabel: async function() {
      this.loadingCreate = true;
      let label = "";

      if (this.label.location) {
        label = label + this.label.location + "-";
      }
      if (this.label.type) {
        label = label + this.label.type + "-";
      }
      if (this.label.room) {
        label = label + this.label.room + "-";
      }
      if (this.label.shelfNumber) {
        let shelfNumber = this.label.shelfNumber;
        //if shelfNumber is a single digit number, add a 0 in front
        if (shelfNumber.length === 1) {
          shelfNumber = "0" + shelfNumber;
        }
        label = label + shelfNumber + "-";
      }
      if (this.label.typeNumber) {
        let typeNumber = this.label.typeNumber;
        //if typeNumber is a single digit number, add a 0 in front
        if (typeNumber.length === 1) {
          typeNumber = "0" + typeNumber;
        }
        label = label + typeNumber + "-";
      }

      if (this.label.port1) {
        let port1 = this.label.port1;
        //if port1 is a single digit number, add a 0 in front
        if (port1.length === 1) {
          port1 = "0" + port1;
        }
        label = label + port1 + "-";
      }
      if (this.label.port2) {
        let port2 = this.label.port2;
        //if port2 is a single digit number, add a 0 in front
        if (port2.length === 1) {
          port2 = "0" + port2;
        }
        label = label + port2 + "-";
      }

      //if last character is a dash, remove it
      if (label.charAt(label.length - 1) === "-") {
        label = label.substring(0, label.length - 1);
      }
      this.createdLabel = label;

      this.loadingCreate = false;
    },
  },
};
</script>
<style scoped></style>
