<template>
  <div id="Einfuehrung">
    <!-- Einführung -->
    <v-container>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <span :class="typography.site"
            >Allgemeines Beschriftungsschema der Kliniken des Landkreises
            Neustadt a. d. Aisch/Bad Windsheim im Bereich EDV</span
          >
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Das allgemeine Beschriftungsschema bezieht sich sowohl auf die
          Kliniken in Neustadt a. d. Aisch und Bad Windsheim als auch auf die
          Medizinischen Versorgungszentren der Kliniken. Das Beschriftungsschema
          soll in erster Linie die Netzwerkinfrastruktur beschreiben.
          Tiefergehende Angelegenheiten, wie Server und Storage sind von diesem
          Schema ausgeschlossen und werden speziell behandelt.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
    </v-container>
    <!-- Aufbau -->
    <v-container>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#Aufbau"
        headline="1. Aufbau der Beschriftung"
        id="Aufbau"
        typographyType="title"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Die einzelnen Segmente des durch die Beschriftung entstehenden Namens
          werden ausschließlich durch Bindestriche getrennt. Die einzige
          Ausnahme bildet die Beschriftung einer Netzwerkdose. Hierbei wird Port
          1 mit einem Schrägstrich von Port 2 getrennt.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#AufbauSegmente"
        headline="1.1 Segmente einer Beschriftung"
        id="AufbauSegmente"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Im Folgenden werden die in der Beschriftung verwendeten Segmente
          aufgelistet und definiert.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="segmentTable.headers"
            :items="segmentTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Beim Anfügen eines Werts zu einem der Segmente muss unbedingt auf die
          Stellenanzahl der Segmente geachtet werden, um die Einheitlichkeit und
          die Möglichkeit der Verarbeitung in verschiedenen Anwendungen zu
          gewährleisten.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
    </v-container>

    <!-- Werte der Segmente -->
    <v-container>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#Werte"
        headline="2. Werte der Segmente"
        id="Werte"
        typographyType="title"
      />
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#WerteStandort"
        headline='2.1 "Standort"-Segment'
        id="WerteStandort"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Das zweistellige „Standort“-Segment stellt mit einem führenden
          Buchstaben die geographische Lage dar. Die darauf folgende Nummer gibt
          die fortlaufende Nummerierung der Zweigstellen an dem an erster Stelle
          festgelegten Ort an.
          <br />
          Werte die das „Standort“-Segment zum aktuellen Zeitpunkt annehmen
          kann:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="standortTable.headers"
            :items="standortTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#WerteArt"
        headline='2.2 "Art"-Segment'
        id="WerteArt"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Im dreistelligen „Art“-Segment wird durch eine Abkürzung die zu
          beschriftende Komponente angegeben.
          <br />
          Werte die das „Art“-Segment zum aktuellen Zeitpunkt annehmen kann:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="artTable.headers"
            :items="artTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#WerteRaum"
        headline='2.3 "Raum"-Segment'
        id="WerteRaum"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Das „Raum“-Segment kann in zwei Bereiche aufgeteilt werden. Der erste
          Bereich besteht aus dem ersten Zeichen und beschreibt das Stockwerk /
          die Etage. Die Etagen „Untergeschoss“ und „Erdgeschoss“ werden mit dem
          jeweiligen Anfangsbuchstaben belegt. Die darauffolgenden Stockwerke
          jeweils mit ihrer Zahl. Der zweite Bereich, bestehend aus den letzten
          beiden Zeichen, beschreibt die eindeutige Nummer des Raums auf der im
          ersten Bereich festgelegten Etage. Die Nummerierung des Raumes wird
          fortlaufend, aufsteigend verwendet. Für die Netzwerkinfrastruktur
          essentielle Räume sind Ausnahmen und erhalten eigene eindeutige Namen.
          Diese Namen müssen außerdem standortübergreifend eindeutig sein.
          <br />
          Diese eindeutigen Namen werden im Folgenden aufgelistet:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="specialRaumTable.headers"
            :items="specialRaumTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Beispiele für die Verwendung des „Raum“-Segments:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="raumExampleTable.headers"
            :items="raumExampleTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Werte die das „Raum“-Segment zum aktuellen Zeitpunkt annehmen kann:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="roomListTable.headers"
            :items="roomListTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#WerteSchrankNr"
        headline='2.4 "Schrank Nr."-Segment'
        id="WerteSchrankNr"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body"
          >Das „Schrank-Nr.“-Segment ist eine zweistellige fortlaufende Zahl die
          in jedem Raum ab 01 beginnend vergeben wird und nur im jeweiligen Raum
          eindeutig sein muss.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#WerteArtNr"
        headline='2.5 "Art Nr."-Segment'
        id="WerteArtNr"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Das „Art-Nr.“-Segment ist eine zweistellige fortlaufende Zahl die in
          jedem Schrank ab 01 beginnend für die jeweilige Art der Komponente
          vergeben wird und nur in diesem Schrank eindeutig sein muss. Sie gibt
          fortlaufend die Anzahl der Geräte mit der im „Art“-Segment angegebenen
          Art im „Schrank“-Segment angegebenen Schrank an.
          <br />
          Eine Ausnahme für dieses Segment bildet die Beschriftung eines
          Patchpanels (Siehe 4.1).
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#WertePort"
        headline='2.6 "Port 1"/"Port 2"-Segment'
        id="WertePort"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Das „Port 1“/„Port 2“-Segement kommt nur bei der Beschriftung von
          Dosen bzw. beschränkt bei Beschriftung von Switchports zum Einsatz.
          Als Wert wird die Nummer des Patchfeldports oder des Switchports
          verwendet.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
    </v-container>
    <v-container>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#ZusammenfuehrungDerSegmente"
        headline="3. Zusammenführung der Segmente"
        id="ZusammenfuehrungDerSegmente"
        typographyType="title"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Die einzelnen Segmente werden folgendermaßen zu einem Namen
          zusammengeführt, wobei die in eckigen Klammern gefassten Segmente
          optional sind und vom „Art“-Segment abhängen.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <span class="font-weight-bold"
            >[Standort - ] [Art - ] Raum – SchrankNr – ArtNr [ - Port1] [
            /Port2]</span
          >
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Zur Förderung der Verständlichkeit sind hier einige Beispiele
          aufgelistet:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="mergeExampleTable.headers"
            :items="mergeExampleTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Genauere Ausführung des letzten Beispiels:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="specificMergeExampleTable.headers"
            :items="specificMergeExampleTable.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
    </v-container>
    <v-container>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#Besonderheiten"
        headline="4. Besonderheiten in der Beschriftung (Ausnahmen)"
        id="Besonderheiten"
        typographyType="title"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body"
          >Im Folgenden werden unterschiedliche Besonderheiten oder Ausnahmen
          des Beschriftungsschemas definiert.</v-col
        >
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#BesonderheitenPatchpanel"
        headline="4.1 Beschriftung eines Patchkabels (PC7, PC6, PCM, PCS)"
        id="BesonderheitenPatchpanel"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Für Patchpanel gilt eine Ausnahmen bei der Wahl des
          „Art-Nr.“-Segments. Normalerweise ist die „Art-Nr.“ für jede „Art“ von
          01 an fortlaufend. Das würde bedeuten, dass wenn im Schrank 02 ein
          Multimode-Patchpanel und ein Cat.7 Patchpanel verbaut sind die
          Beschriftung normalerweise PFM-02-01 für das Glasfaserpanel und
          PC7-02-01 für das Kupferpanel wäre.
          <br /><br />
          Für Patchpanel gilt allerdings, dass diese auch ohne die exakte Angabe
          des Typs (Multimode/Cat.7/…) im Schrank einzigartig sein sollen.
          Deshalb teilen sich alle Patchpanel eine fortlaufende Nummerierung im
          „Art-Nr.“-Segment innerhalb eines Schranks. Das würde für das eben
          genannte Beispiel bedeuten, dass die Beschriftung des Glasfaser-Panels
          „PFM-02-01“ und für das Kupferpanel „PC7-02-02“ wäre.
          <br /><br />
          Somit kann die Angabe des Patchpaneltyps bei der Beschriftung einer
          Doppeldose weg gelassen werden (Siehe 4.2).
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#BesonderheitenDoppeldose"
        headline="4.2 Beschriftung einer Doppeldose"
        id="BesonderheitenDoppeldose"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Bei der Beschriftung einer Dose werden das „Standort“-Segment und das
          „Art“-Segment weg gelassen. Die Beschriftung besteht lediglich aus dem
          „Raum“, „Schrank-Nr.“, „Art-Nr.“, „Port 1“ und „Port 2“-Segment.
          <br /><br />
          Über das „Raum“- und „Schrank-Nr.“-Segment wird der genaue
          Netzwerkschrank bekanntgegeben. Durch die „Art-Nr.“ wird die im
          Schrank fortlaufende Nummer des Patchpanels angegeben (siehe 4.1).
          Durch die Port-Segmente werden die zugehörigen Ports auf dem
          Patchpanel angegeben.
          <br /><br />
          So kann die Doppeldosen-Beschriftung so kurz, und übersichtlich wie
          möglich gehalten werden.
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
    </v-container>
    <v-container>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#KabelbeschriftungNetzwerkschrank"
        headline="5. Beschriftung eines Kabels im Netzwerkschrank"
        id="KabelbeschriftungNetzwerkschrank"
        typographyType="title"
      />
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#KabelbeschriftungNetzwerkschrankRichtlinien"
        headline="5.1 Richtlinien"
        id="KabelbeschriftungNetzwerkschrankRichtlinien"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          Für die Beschriftung eines Kabelbinders mit Beschriftungsfeld an einem
          Kabel gelten folgende Richtlinien:
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <ul>
            <li>
              An einem Kabel ist an jedem Ende jeweils eine Beschriftung
              anzubringen
            </li>
            <li>
              Im oberen Bereich des Beschriftungsfeldes eines Kabelbinders steht
              die Beschriftung der Quelle des Kabels von der Seite an der der
              Kabelbinder angebracht ist
            </li>
            <li>
              Im unteren Bereich des Beschriftungsfeldes eines Kabelbinders
              steht die Beschriftung des Ziels des Kabels von der Seite an der
              der Kabelbinder angebracht ist
            </li>
            <li>
              In der Kabelbeschriftung müssen nur folgende Segmente der
              Beschriftung enthalten sein. Die restlichen Segmente können/sollen
              weggelassen werden um die Übersichtlichkeit zu erhöhen
            </li>
            <ul>
              <li>Art-Segment</li>
              <li>Schrank-Segment</li>
              <li>Art-Nr.-Segment</li>
              <li>Ggf. Port-1-Segment</li>
            </ul>
          </ul>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <heading
        :typography="typography"
        :colSpacing="colSpacing"
        link="/concept/#KabelbeschriftungNetzwerkschrankBeispiele"
        headline="5.2 Beispiele"
        id="KabelbeschriftungNetzwerkschrankBeispiele"
        typographyType="subtitle"
      />
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <span class="text-subtitle-1 font-weight-bold"
            >Beschriftung eines OM4 LWL Uplink Patchkabels eines
            Accessswitches</span
          >
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body"
          >Verbindung „2. Accessswitch – Port 51“ zu „3. Multimode Patchpanel zu
          RZ1 – Port 2“ im Schrank 1</v-col
        >
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="cableExampleTable1.headers"
            :items="cableExampleTable1.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <span class="font-weight-bold"
            >Beschriftung eines Stromkabels eines Accessswitches</span
          >
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body"
          >Verbindung „3. Accessswitch – Netzteil 1“ zu „USV“ im Schrank
          2</v-col
        >
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body">
          <v-data-table
            :headers="cableExampleTable2.headers"
            :items="cableExampleTable2.items"
            disable-pagination
            hide-default-footer
            class="primary"
          ></v-data-table>
        </v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
    </v-container>
    <!-- <v-container>
      <v-row>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
        <v-col :cols="colSpacing.mid" :class="typography.body"></v-col>
        <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
      </v-row>
    </v-container> -->
  </div>
</template>
<script>
import heading from "../components/heading.vue";
import { getLocations, getRooms, getTypes } from "@/plugins/vuetify/helpers.js";
export default {
  name: "Concept",
  components: {
    heading,
  },
  computed: {
    colSpacing: function() {
      let cols = {
        left: 1,
        mid: 10,
        right: 1,
      };
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          cols.left = 0;
          cols.right = 0;
          cols.mid = 12;
          break;
        case "sm":
          cols.left = 0;
          cols.right = 0;
          cols.mid = 12;
          break;
      }
      return cols;
    },
    typography: function() {
      let typography = {
        site: "text-h4",
        title: "text-h5 secondary--text font-weight-bold",
        subtitle: "text-h6 secondary--text",
        body: "text-body-1",
      };
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          typography.site = "text-h6";
          typography.title = "text-subtitle-1 secondary--text font-weight-bold";
          typography.subtitle = "text-subtitle-2 secondary--text";
          typography.body = "text-body-2";
          break;
        case "sm":
          typography.site = "text-h5";
          typography.title = "text-h6 secondary--text font-weight-bold";
          typography.subtitle = "text-subtitle-1 secondary--text";
          typography.body = "text-body-1";
          break;
      }
      return typography;
    },
  },
  async mounted() {
    await this.getLocations();
    await this.getTypes();
    await this.getRooms();
  },
  methods: {
    getLocations: async function() {
      let locations = await getLocations();
      this.standortTable.items = locations;
    },
    getTypes: async function() {
      let types = await getTypes();
      this.artTable.items = types;
    },
    getRooms: async function() {
      let rooms = await getRooms();
      let locations = await getLocations();
      for (let i = 0; i < rooms.length; i++) {
        let room = rooms[i];
        let roomToAdd = {
          short: null,
          location: null,
          long: null,
        };
        roomToAdd.short = room.short;
        roomToAdd.long = room.long;

        //find location in locations by id
        for (let j = 0; j < locations.length; j++) {
          let location = locations[j];
          if (location.id == room.location_id) {
            roomToAdd.location = location.alias + " (" + location.short + ")";
          }
        }
        this.roomListTable.items.push(roomToAdd);
        console.log(roomToAdd);
      }
    },
  },
  data() {
    return {
      activeHash: {
        aufbau: false,
        aufbauSegmente: false,
        werte: false,
        werteStandort: false,
        werteArt: false,
        werteRaum: false,
        werteSchrankNr: false,
        werteArtNr: false,
        wertePort: false,
        zusammenfuehrungDerSegmente: false,
        besonderheiten: false,
        besonderheitenPatchpanel: false,
        besonderheitenDoppeldose: false,
        kabelbeschriftungNetzwerkschrank: false,
        kabelbeschriftungNetzwerkschrankRichtlinien: false,
        kabelbeschriftungNetzwerkschrankBeispiele: false,
      },
      raumExampleTable: {
        headers: [
          {
            text: "Beispiel-Segment",
            align: "start",
            sortable: false,
            value: "value",
          },
          {
            text: "Bedeutung",
            align: "start",
            sortable: false,
            value: "meaning",
          },
        ],
        items: [
          {
            value: "U02",
            meaning: "Der zweite Verteilerraum im Untergeschoss",
          },
          {
            value: "E10",
            meaning: "Der zehnte Verteilerraum im Erdgeschoss",
          },
          {
            value: "305",
            meaning: "Der fünfte Verteilerraum im dritten Stockwerk",
          },
        ],
      },
      specialRaumTable: {
        headers: [
          {
            text: "Segment-Wert",
            align: "start",
            sortable: true,
            value: "value",
          },
          {
            text: "Raum/Ort",
            align: "start",
            sortable: true,
            value: "description",
          },
          {
            text: "Standort",
            align: "start",
            sortable: true,
            value: "location",
          },
        ],
        items: [
          {
            value: "RZ1",
            description: "Rechenzentrum 1",
            location: "Klinik Neustadt a. d. Aisch",
          },
          {
            value: "RZ2",
            description: "Rechenzentrum 2",
            location: "Klinik Neustadt a. d. Aisch",
          },
          {
            value: "GHV",
            description: "Gebäudehauptverteiler",
            location: "Klinik Bad Windsheim",
          },
        ],
      },
      roomListTable: {
        headers: [
          {
            text: "Segment-Wert",
            align: "start",
            sortable: true,
            value: "short",
          },
          {
            text: "Zugehöriger Standort",
            align: "start",
            sortable: true,
            value: "location",
          },
          {
            text: "Beschreibung",
            align: "start",
            sortable: true,
            value: "long",
          },
        ],
        items: [],
      },
      artTable: {
        headers: [
          {
            text: "Segment-Wert",
            align: "start",
            sortable: true,
            value: "short",
          },
          {
            text: "Beschreibung",
            align: "start",
            sortable: true,
            value: "long",
          },
        ],
        items: [],
      },
      standortTable: {
        headers: [
          {
            text: "Segment-Wert",
            align: "start",
            sortable: true,
            value: "short",
          },
          {
            text: "Beschreibung",
            align: "start",
            sortable: true,
            value: "alias",
          },
          {
            text: "PLZ",
            align: "start",
            sortable: true,
            value: "postal_code",
          },
          {
            text: "Ort",
            align: "start",
            sortable: true,
            value: "city",
          },
          {
            text: "Straße",
            align: "start",
            sortable: true,
            value: "street",
          },
          {
            text: "Hausnummer",
            align: "start",
            sortable: true,
            value: "number",
          },
        ],
        items: [],
      },
      cableExampleTable2: {
        headers: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "line",
          },
          {
            text: "Beschriftung - Seite: USV",
            align: "start",
            sortable: false,
            value: "usvDesc",
          },
          {
            text: "Beschriftung - Seite: Accessswitch",
            align: "start",
            sortable: false,
            value: "switchDesc",
          },
        ],
        items: [
          {
            line: "Obere Zeile des Beschriftungsfeldes",
            usvDesc: "USV",
            switchDesc: "SWA-02-03 PSU-1",
          },
          {
            line: "Untere Zeile des Beschriftungsfeldes",
            usvDesc: "SWA-02-03 PSU-1",
            switchDesc: "USV",
          },
        ],
      },
      cableExampleTable1: {
        headers: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "line",
          },
          {
            text: "Beschriftung - Seite: Patchpanel",
            align: "start",
            sortable: false,
            value: "patchDesc",
          },
          {
            text: "Beschriftung - Seite: Accessswitch",
            align: "start",
            sortable: false,
            value: "switchDesc",
          },
        ],
        items: [
          {
            line: "Obere Zeile des Beschriftungsfeldes",
            patchDesc: "PFM-01-03-02",
            switchDesc: "SWA-01-02-51",
          },
          {
            line: "Untere Zeile des Beschriftungsfeldes",
            patchDesc: "SWA-01-02-51",
            switchDesc: "PFM-01-03-02",
          },
        ],
      },
      specificMergeExampleTable: {
        headers: [
          {
            text: "Segment-Wert",
            align: "start",
            sortable: false,
            value: "value",
          },
          {
            text: "Segment",
            align: "start",
            sortable: false,
            value: "segment",
          },
          {
            text: "Bedeutung",
            align: "start",
            sortable: false,
            value: "meaning",
          },
        ],
        items: [
          {
            value: "N",
            segment: "Standort",
            meaning: "Geographische Lage: Neustadt a. d. Aisch",
          },
          {
            value: "1",
            segment: "Standort",
            meaning:
              "Erste Zweigstelle in Neustadt a. d. Aisch: Klinik Neustadt a. d. Aisch",
          },
          {
            value: "-",
            segment: "/",
            meaning: "Segmenttrennzeichen",
          },
          {
            value: "SWC",
            segment: "Art",
            meaning: "Core-Switch",
          },
          {
            value: "-",
            segment: "/",
            meaning: "Segmenttrennzeichen",
          },
          {
            value: "2",
            segment: "Raum",
            meaning: "2. Stock im Gebäude",
          },
          {
            value: "03",
            segment: "Raum",
            meaning: "3. Verteilerraum im festgelegten Stockwerk",
          },
          {
            value: "-",
            segment: "/",
            meaning: "Segmenttrennzeichen",
          },
          {
            value: "05",
            segment: "Schrank Nr.",
            meaning: "5. Schrank im festgelegten Raum",
          },
          {
            value: "-",
            segment: "/",
            meaning: "Segmenttrennzeichen",
          },
          {
            value: "01",
            segment: "Art Nr.",
            meaning: "1. Core-Switch im festgelegten Schrank",
          },
          {
            value: "-",
            segment: "/",
            meaning: "Segmenttrennzeichen",
          },
          {
            value: "29",
            segment: "Port",
            meaning: "Port 29 am festgelegten Switch",
          },
        ],
      },
      mergeExampleTable: {
        headers: [
          {
            text: "Beispiel-Beschriftung",
            align: "start",
            sortable: false,
            value: "label",
          },
          {
            text: "Bedeutung",
            align: "start",
            sortable: false,
            value: "meaning",
          },
          {
            text: "Beschriftete Komponente",
            align: "start",
            sortable: false,
            value: "component",
          },
        ],
        items: [
          {
            label: "N1-SWA-201-02-04",
            meaning:
              "Klinik Neustadt a. d. Aisch – Accesswitch – 2. Stock, 1. Verteilerraum – 2. Schrank – 4. Accessswitch",
            component: "(Access-)Switch",
          },
          {
            label: "401-01-02-05/06",
            meaning:
              "4. Stock, 1. Verteilerraum – 1. Schrank – 2. Patchpanel – Ports 5&6",
            component: "Netzwerkdose",
          },
          {
            label: "N1-DVS-101-01",
            meaning:
              "Klinik Neustadt a. d. Aisch – Datenverteilerschrank – 1. Stock, 1. Verteilerraum – 1. Schrank",
            component: "Datenverteilerschrank",
          },
          {
            label: "B1-TMP-GHV-02-03",
            meaning:
              "Klinik Bad Windsheim – Temperatursensor – Gebäudehauptverteiler – 2. Schrank – 3. Temperatursensor",
            component: "Temperatursensor",
          },
          {
            label: "B1-PC6-E01-01-02",
            meaning:
              "Klinik Bad Windsheim – Kupfer Patchfeld Cat. 6 / 6a – Erdgeschoss, 1. Verteilerraum – 1. Schrank – 2. Patchpanel",
            component: "Kupfer Patchpanel der Kategorie 6 bzw. 6a",
          },
          {
            label: "N1-SWC-203-05-01-29",
            meaning:
              "Klinik Neustadt a. d. Aisch – Core-Switch – 2. Stock, 3. Verteilerraum – 5. Schrank – 1. Core-Switch – Port 29",
            component: "Switchport",
          },
        ],
      },
      segmentTable: {
        headers: [
          {
            text: "Segment",
            align: "start",
            sortable: false,
            value: "name",
          },
          {
            text: "Beschreibung",
            align: "start",
            sortable: false,
            value: "description",
          },
          {
            text: "Stellenanzahl",
            align: "start",
            sortable: false,
            value: "length",
          },
        ],
        items: [
          {
            name: "Standort",
            description:
              "Eindeutige Angabe des Standorts durch einen Buchstaben und eine Zahl. MVZs die sich im Gebäude eines übergeordneten Standorts befinden erhalten das Standort-Segment des übergeordneten Standorts.",
            length: 2,
          },
          {
            name: "Art",
            description: "Angabe der zu beschriftenden Komponente.",
            length: 3,
          },
          {
            name: "Raum",
            description:
              "Eindeutige Angabe des Raums innerhalb des Standorts. Angegeben werden das Stockwerk und die vergebene Nummer des Verteilerraums.",
            length: 3,
          },
          {
            name: "Schrank Nr.",
            description:
              "Eindeutige Angabe der Schrank Nr. innerhalb des Raumes in dem sich die angegebene Komponente befindet.",
            length: 2,
          },
          {
            name: "Art Nr.",
            description:
              "Eindeutige fortlaufende Nummer der Komponente im angegebenen Schrank.",
            length: 2,
          },
          {
            name: "Port 1",
            description:
              "Falls es sich um eine Netzwerkdose handelt oder ein Switchport angegeben wird.",
            length: 2,
          },
          {
            name: "Port 2",
            description: "Falls es sich um eine Netzwerkdose handelt.",
            length: 2,
          },
        ],
      },
    };
  },
};
</script>
<style scoped></style>
