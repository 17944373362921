<template>
  <div class="test">
    <v-app-bar class="primary" fixed>
      <v-toolbar-items @click.stop="openDrawer" class="pa-0 hidden-md-and-up">
        <v-app-bar-nav-icon class="pl-0 ml-n4">
          <v-icon color="white">mdi-menu</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar-items>
      <div style="width:44px;" class="hidden-sm-and-down"></div>
      <v-spacer></v-spacer>
      <v-toolbar-title :class="responsiveMargin"
        >Naming-Tool - Kliniken NEA</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        <toggleDarkMode />
      </div>
      <div>
        <v-btn icon v-if="!$globalState.isLoggedIn" to="login">
          <v-icon>mdi-login</v-icon>
        </v-btn>
        <v-btn icon v-else @click="handleLogout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <!-- SM and down view -->
    <v-navigation-drawer
      class="hidden-md-and-up"
      v-model="drawer"
      width="370"
      :height="this.$vuetify.breakpoint.height"
      color="background"
      fixed
    >
      <v-app-bar class="primary">
        <v-row>
          <v-col cols="9" class="text-h6">Naming-Tool</v-col>
          <v-col cols="3" class="d-flex justify-end align-center">
            <toggleDarkMode />
          </v-col>
        </v-row>
      </v-app-bar>
      <v-list>
        <div class="ma-0 pa-0" v-for="view in views" :key="view.name">
          <v-list-item
            v-if="!view.group"
            link
            :to="view.link"
            active-class="secondary--text"
          >
            <v-list-item-icon>
              <v-icon>{{ view.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ view.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-group
            v-else
            :prepend-icon="view.icon"
            active-class="secondary--text"
            v-model="view.active"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ view.name }}</v-list-item-title>
            </template>
            <div v-for="item in view.items" :key="item.name">
              <v-list-item
                v-if="!item.subGroup"
                link
                :to="item.link"
                active-class="secondary--text"
              >
                <v-list-item-icon class="ml-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="ml-n4">
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
              <v-list-group
                v-else
                sub-group
                active-class="secondary--text"
                v-model="item.subActive"
                @click="collapseSubGroups(item.name)"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item
                  v-for="subItem in item.subItems"
                  :key="subItem.name"
                  link
                  :to="subItem.link"
                  active-class="secondary--text"
                >
                  <v-list-item-icon>
                    <v-icon>{{ subItem.icon }}</v-icon>
                  </v-list-item-icon>
                  <span>
                    {{ subItem.name }}
                  </span>
                </v-list-item>
              </v-list-group>
            </div>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!-- MD and up view -->
    <v-navigation-drawer
      class="hidden-sm-and-down"
      width="370"
      :height="this.$vuetify.breakpoint.height"
      color="background"
      permanent
      fixed
      expand-on-hover
      @mouseenter="openActiveGroup"
    >
      <div
        @mouseenter="onExpandDesktop"
        style="height: 100%;"
        @mouseleave="collapseGroups"
      >
        <v-app-bar class="primary"> </v-app-bar>
        <v-list>
          <div class="ma-0 pa-0" v-for="view in views" :key="view.name">
            <v-list-item
              v-if="!view.group"
              link
              :to="view.link"
              active-class="secondary--text"
            >
              <v-list-item-icon>
                <v-icon>{{ view.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                {{ view.name }}
              </v-list-item-title>
            </v-list-item>
            <v-list-group
              v-else
              :prepend-icon="view.icon"
              active-class="secondary--text"
              v-model="view.active"
            >
              <template v-slot:activator>
                <v-list-item-title>{{ view.name }}</v-list-item-title>
              </template>
              <div v-for="item in view.items" :key="item.name">
                <v-list-item
                  v-if="!item.subGroup"
                  link
                  :to="item.link"
                  active-class="secondary--text"
                >
                  <v-list-item-icon class="ml-4">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-n4">
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-group
                  v-else
                  sub-group
                  active-class="secondary--text"
                  v-model="item.subActive"
                  @click="collapseSubGroups(item.name)"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                    v-for="subItem in item.subItems"
                    :key="subItem.name"
                    link
                    :to="subItem.link"
                    active-class="secondary--text"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ subItem.icon }}</v-icon>
                    </v-list-item-icon>
                    <span>
                      {{ subItem.name }}
                    </span>
                  </v-list-item>
                </v-list-group>
              </div>
            </v-list-group>
          </div>
        </v-list>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import toggleDarkMode from "./toggleDarkMode.vue";
export default {
  name: "navbar",
  components: {
    toggleDarkMode,
  },
  data() {
    return {
      drawer: false,
      views: [
        { name: "Erstellen", link: "/", icon: "mdi-new-box", group: false },
        {
          name: "Auflösen",
          link: "/resolve",
          icon: "mdi-tag-arrow-right",
          group: false,
        },
        {
          name: "Stammdaten",
          link: "/data",
          icon: "mdi-database",
          group: false,
        },
        {
          name: "Konzept",
          link: "/concept",
          icon: "mdi-book-open-blank-variant",
          group: true,
          active: false,
          items: [
            {
              name: "Einführung",
              link: "/concept/#Einfuehrung",
              icon: "mdi-source-commit-start",
              subGroup: false,
            },
            {
              name: "Aufbau",
              link: "/concept/#Aufbau",
              icon: "",
              subGroup: true,
              subActive: false,
              subItems: [
                {
                  name: "Segmente",
                  link: "/concept/#AufbauSegmente",
                  icon: "mdi-segment",
                },
              ],
            },
            {
              name: "Werte der Segmente",
              link: "/concept/#Werte",
              icon: "",
              subGroup: true,
              subActive: false,
              subItems: [
                {
                  name: "Standort",
                  link: "/concept/#WerteStandort",
                  icon: "mdi-map-marker",
                },
                {
                  name: "Art",
                  link: "/concept/#WerteArt",
                  icon: "mdi-developer-board",
                },
                {
                  name: "Raum",
                  link: "/concept/#WerteRaum",
                  icon: "mdi-office-building",
                },
                {
                  name: "Schrank-Nr.",
                  link: "/concept/#WerteSchrankNr",
                  icon: "mdi-cupboard",
                },
                {
                  name: "Art-Nr.",
                  link: "/concept/#WerteArtNr",
                  icon: "mdi-counter",
                },
                {
                  name: "Port",
                  link: "/concept/#WertePort",
                  icon: "mdi-import",
                },
              ],
            },
            {
              name: "Zusammenführung der Segmente",
              link: "/concept/#ZusammenfuehrungDerSegmente",
              icon: "mdi-set-merge",
              subGroup: false,
            },
            {
              name: "Besonderheiten",
              link: "/concept/#Besonderheiten",
              icon: "",
              subGroup: true,
              subActive: false,
              subItems: [
                {
                  name: "Patchpanel",
                  link: "/concept/#BesonderheitenPatchpanel",
                  icon: "mdi-drag-horizontal",
                },
                {
                  name: "Doppeldose",
                  link: "/concept/#BesonderheitenDoppeldose",
                  icon: "mdi-ethernet",
                },
              ],
            },
            {
              name: "Kabelbeschriftung im Netzwerkschrank",
              link: "/concept/#KabelbeschriftungNetzwerkschrank",
              icon: "",
              subGroup: true,
              subActive: false,
              subItems: [
                {
                  name: "Richtlinien",
                  link: "/concept/#KabelbeschriftungNetzwerkschrankRichtlinien",
                  icon: "mdi-book-alert",
                },
                {
                  name: "Beispiele",
                  link: "/concept/#KabelbeschriftungNetzwerkschrankBeispiele",
                  icon: "mdi-ab-testing",
                },
              ],
            },
            // {
            //   name: "Naming-Tool Dokumentation",
            //   link: "/concept/#Naming-ToolDokumentation",
            //   icon: "",
            //   subGroup: true,
            //   subActive: false,
            //   subItems: [
            //     {
            //       name: "Erstellen",
            //       link: "/concept/#Naming-ToolDokumentationErstellen",
            //       icon: "mdi-new-box",
            //     },
            //     {
            //       name: "Auflösen",
            //       link: "/concept/#Naming-ToolDokumentationAufloesen",
            //       icon: "mdi-tag-arrow-right",
            //     },
            //     {
            //       name: "Stammdaten",
            //       link: "/concept/#Naming-ToolDokumentationStammdaten",
            //       icon: "mdi-database",
            //     },
            //   ],
            // },
          ],
        },
      ],
    };
  },
  computed: {
    responsiveMargin: function() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h6";
        case "sm":
          return "text-h5";
        case "md":
          return "ml-16 text-h5";
        case "lg":
          return "ml-16 text-h5";
        case "xl":
          return "ml-16 text-h5";
        default:
          return "ml-16 text-h5";
      }
    },
  },
  methods: {
    handleLogout: async function() {
      await supabase.auth.signOut();
      this.$router.push("/");
    },
    activeLinkIconColor: function(link) {
      if (this.$route.path === link) {
        return "secondary";
      } else if (this.$vuetify.theme.dark) {
        return "white";
      } else {
        return "black";
      }
    },
    activeLinkTextColor: function(link) {
      if (this.$route.path === link) {
        return "secondary--text";
      } else if (this.$vuetify.theme.dark) {
        return "white--text";
      } else {
        return "black--text";
      }
    },
    openDrawer: function() {
      this.drawer = !this.drawer;
      this.openActiveGroup();
    },
    openActiveGroup: function() {
      let fullPath = this.$route.path + this.$route.hash;
      for (let i = 0; i < this.views.length; i++) {
        if (this.views[i].group) {
          if (fullPath.includes(this.views[i].link)) {
            this.views[i].active = true;
            for (let k = 0; k < this.views[i].items.length; k++) {
              if (this.views[i].items[k].subGroup) {
                if (fullPath.includes(this.views[i].items[k].link)) {
                  this.views[i].items[k].subActive = true;
                }
              }
            }
          }
        }
      }
    },
    collapseGroups: function() {
      for (let i = 0; i < this.views.length; i++) {
        if (this.views[i].group) {
          for (let k = 0; k < this.views[i].items.length; k++) {
            if (this.views[i].items[k].subGroup) {
              this.views[i].items[k].subActive = false;
            }
          }
          this.views[i].active = false;
        }
      }
    },
    onExpandDesktop: async function() {
      await this.Sleep(300);
      this.openActiveGroup();
    },
    Sleep: function(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    collapseSubGroups: function(activeName) {
      for (let i = 0; i < this.views.length; i++) {
        if (this.views[i].group) {
          for (let k = 0; k < this.views[i].items.length; k++) {
            if (this.views[i].items[k].subGroup) {
              if (this.views[i].items[k].name !== activeName) {
                this.views[i].items[k].subActive = false;
              }
            }
          }
        }
      }
    },
  },
};
</script>
