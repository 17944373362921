<template>
  <v-expansion-panels class="background px-4">
    <v-expansion-panel class="primary">
      <v-expansion-panel-header class="font-weight-bold">
        Standort bearbeiten oder löschen
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-form v-model="validLocation" ref="locationForm">
            <v-col cols="10">
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="Zu bearbeitender Standort"
                    :items="locations"
                    item-value="id"
                    item-text="alias"
                    v-model="location.id"
                    color="secondary"
                    item-color="secondary"
                    @change="loadLocation"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Kürzel"
                    v-model="location.short"
                    color="secondary"
                    :rules="[shortExactlyTwoChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Beschreibung"
                    v-model="location.alias"
                    color="secondary"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="PLZ"
                    v-model="location.postalCode"
                    color="secondary"
                    type="number"
                    :rules="[postalCodeExactlyFiveChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Stadt"
                    v-model="location.city"
                    color="secondary"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Straße"
                    v-model="location.street"
                    color="secondary"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Nummer"
                    v-model="location.number"
                    color="secondary"
                    type="number"
                    :rules="[notEmpty]"
                    @keyup.enter="saveLocation"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
          <v-col class="d-flex align-center">
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    color="secondary"
                    @click="updateLocation"
                    :disabled="!validLocation"
                    >Speichern</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="error"
                    @click="deleteLocation"
                    :disabled="!validLocation"
                    >Löschen</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel class="primary">
      <v-expansion-panel-header class="font-weight-bold">
        Raum bearbeiten oder löschen
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="8">
            <v-form v-model="validRoom" ref="roomForm">
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="Standort für zu bearbeitenden Raum"
                    :items="locations"
                    item-value="id"
                    item-text="alias"
                    v-model="room.selectLocationId"
                    color="secondary"
                    item-color="secondary"
                    @change="loadLocationRooms"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    label="Zu bearbeitender Raum"
                    :items="locationRooms"
                    item-value="id"
                    item-text="long"
                    v-model="room.id"
                    color="secondary"
                    item-color="secondary"
                    @change="loadRoom"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center">
                  <v-autocomplete
                    label="Standort"
                    v-model="room.locationId"
                    item-value="id"
                    item-text="alias"
                    :items="locations"
                    color="secondary"
                    :rules="[notEmpty]"
                    item-color="secondary"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Kürzel"
                    v-model="room.short"
                    color="secondary"
                    :rules="[shortExactlyThreeChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Beschreibung"
                    v-model="room.long"
                    color="secondary"
                    :rules="[notEmpty]"
                    @keyup.enter="saveRoom"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col class="d-flex align-center">
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    color="secondary"
                    @click="updateRoom"
                    :disabled="!validRoom"
                    >Speichern</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="error"
                    @click="deleteRoom"
                    :disabled="!validRoom"
                    >Löschen</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel class="primary">
      <v-expansion-panel-header class="font-weight-bold">
        Art bearbeiten oder löschen
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="8">
            <v-form v-model="validType" ref="typeForm">
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="Zu bearbeitender Typ"
                    :items="types"
                    item-value="id"
                    item-text="long"
                    v-model="type.id"
                    color="secondary"
                    item-color="secondary"
                    @change="loadType"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Kürzel"
                    v-model="type.short"
                    color="secondary"
                    :rules="[shortExactlyThreeChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Beschreibung"
                    v-model="type.long"
                    color="secondary"
                    :rules="[notEmpty]"
                    @keyup.enter="saveType"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  Notwendige Angaben
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    :label="type.long ? type.long + ' (Art. Nr)' : 'Art. Nr'"
                    color="secondary"
                    v-model="type.requirements.typeNumber"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    label="Schrank Nr."
                    color="secondary"
                    v-model="type.requirements.shelfNumber"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    label="Port 1"
                    color="secondary"
                    v-model="type.requirements.port1"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    label="Port 2"
                    color="secondary"
                    v-model="type.requirements.port2"
                    :disabled="!type.requirements.port1"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col class="d-flex align-center">
            <v-container>
              <v-row>
                <v-col>
                  <v-btn
                    color="secondary"
                    @click="updateType"
                    :disabled="!validType"
                    >Speichern</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    color="error"
                    @click="deleteType"
                    :disabled="!validType"
                    >Löschen</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { getLocations, getRooms, getTypes } from "@/plugins/vuetify/helpers.js";
export default {
  name: "editDelDataPanels",
  data() {
    return {
      location: {
        id: null,
        short: null,
        alias: null,
        postalCode: null,
        city: null,
        street: null,
        number: null,
      },
      room: {
        id: null,
        selectLocationId: null,
        short: null,
        long: null,
        locationId: null,
      },
      type: {
        id: null,
        short: null,
        long: null,
        requirements: {
          typeNumber: false,
          shelfNumber: false,
          port1: false,
          port2: false,
        },
      },
      locations: [],
      rooms: [],
      types: [],
      locationRooms: [],
      validLocation: false,
      validRoom: false,
      validType: false,
    };
  },
  mounted() {
    this.getLocations();
    this.getRooms();
    this.getTypes();
  },
  methods: {
    getLocations: async function() {
      this.locations = await getLocations();
    },
    getRooms: async function() {
      this.rooms = await getRooms();
    },
    getTypes: async function() {
      this.types = await getTypes();
    },
    updateLocation: async function() {
      let locations = await getLocations();
      //check if locations includes item with id = this.location.id
      if (locations.find((location) => location.id == this.location.id)) {
        let response = await supabase
          .from("location")
          .update({
            short: this.location.short,
            alias: this.location.alias,
            postal_code: this.location.postalCode,
            city: this.location.city,
            street: this.location.street,
            number: this.location.number,
            updated: Date.now(),
          })
          .match({ id: this.location.id });
        if (response.status === 200) {
          this.$refs.locationForm.reset();
          this.getLocations();
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Standort erfolgreich bearbeitet",
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "Standort konnte nicht bearbeitet werden",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    updateRoom: async function() {
      let rooms = await getRooms();
      //check if rooms includes item with id = this.room.id
      if (rooms.find((room) => room.id == this.room.id)) {
        let response = await supabase
          .from("room")
          .update({
            short: this.room.short,
            long: this.room.long,
            location_id: this.room.locationId,
            updated: Date.now(),
          })
          .match({ id: this.room.id });
        if (response.status === 200) {
          this.$refs.roomForm.reset();
          this.getRooms();
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Raum erfolgreich bearbeitet",
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "Raum konnte nicht bearbeitet werden",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    updateType: async function() {
      let types = await getTypes();
      //check if types includes item with id = this.type.id
      if (types.find((type) => type.id == this.type.id)) {
        let response = await supabase
          .from("type")
          .update({
            short: this.type.short,
            long: this.type.long,
            requirements: this.type.requirements,
            updated: Date.now(),
          })
          .match({ id: this.type.id });
        if (response.status === 200) {
          this.$refs.typeForm.reset();
          this.getTypes();
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Art erfolgreich bearbeitet",
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "Art konnte nicht bearbeitet werden",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    deleteLocation: async function() {
      let locations = await getLocations();
      //check if locations includes item with id = this.location.id
      if (locations.find((location) => location.id == this.location.id)) {
        //delete location
        let response = await supabase
          .from("location")
          .delete()
          .match({ id: this.location.id });
        console.log(response);
        if (response.status == 200) {
          this.$refs.locationForm.reset();
          this.getLocations();
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Standort gelöscht",
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message:
              "Fehler beim Löschen; Ggf. gibt es noch Räume an diesem Standort",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    deleteRoom: async function() {
      let rooms = await getRooms();
      //check if rooms includes item with id = this.room.id
      if (rooms.find((room) => room.id == this.room.id)) {
        //delete room
        let response = await supabase
          .from("room")
          .delete()
          .match({ id: this.room.id });
        console.log(response);
        if (response.status == 200) {
          this.$refs.roomForm.reset();
          this.getRooms();
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Raum gelöscht",
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "Fehler beim Löschen",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    deleteType: async function() {
      let types = await getTypes();
      //check if types includes item with id = this.type.id
      if (types.find((type) => type.id == this.type.id)) {
        //delete type
        let response = await supabase
          .from("type")
          .delete()
          .match({ id: this.type.id });
        console.log(response);
        if (response.status == 200) {
          this.$refs.typeForm.reset();
          this.getTypes();
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Typ gelöscht",
          };
          this.$globalState.addNotification(notify);
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "Fehler beim Löschen",
          };
          this.$globalState.addNotification(notify);
        }
      }
    },
    loadLocation: function() {
      //get item from locations where id = this.location.id
      let location = this.locations.find(
        (item) => item.id === this.location.id
      );
      this.location.id = location.id;
      this.location.short = location.short;
      this.location.alias = location.alias;
      this.location.city = location.city;
      this.location.postalCode = location.postal_code;
      this.location.street = location.street;
      this.location.number = location.number;
    },
    loadRoom: function() {
      //get item from rooms where id = this.room.id
      let room = this.rooms.find((item) => item.id === this.room.id);
      this.room.id = room.id;
      this.room.short = room.short;
      this.room.long = room.long;
      this.room.locationId = room.location_id;
    },
    loadType: function() {
      //get item from types where id = this.type.id
      let type = this.types.find((item) => item.id === this.type.id);
      this.type.id = type.id;
      this.type.short = type.short;
      this.type.long = type.long;
      this.type.requirements = type.requirements;
    },
    loadLocationRooms: async function() {
      let locationRooms = this.rooms.filter(
        (room) => room.location_id === this.room.selectLocationId
      );
      this.locationRooms = locationRooms;
    },

    //RULES
    shortExactlyTwoChars: function(value) {
      if (value && value.length === 2) {
        return true;
      } else {
        return "Das Kürzel muss 2 Zeichen lang sein";
      }
    },
    shortExactlyThreeChars: function(value) {
      if (value && value.length === 3) {
        return true;
      } else {
        return "Das Kürzel muss 3 Zeichen lang sein";
      }
    },
    postalCodeExactlyFiveChars: function(value) {
      if (value && value.length === 5) {
        return true;
      } else {
        return "Die Postleitzahl muss 5 Zeichen lang sein";
      }
    },
    notEmpty: function(value) {
      if (value && value.length > 0) {
        return true;
      } else {
        return "Dieses Feld darf nicht leer sein";
      }
    },
  },
};
</script>
<style scoped></style>
