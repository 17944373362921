<template>
  <div>
    <v-container fluid class="pt-16">
      <div class="center">
        <v-card style="width: 400px" color="primary">
          <v-container>
            <v-row>
              <v-col class="d-flex justify-center">
                <span class="heading4 font-weight-bold">Reset Password</span>
              </v-col>
            </v-row>
            <v-form v-model="validReset">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Password"
                    color="secondary"
                    type="password"
                    v-model="reset.password"
                    :rules="[notEmpty, minPasswordRequirements]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Repeat Password"
                    color="secondary"
                    type="password"
                    v-model="reset.password2"
                    :rules="[notEmpty, minPasswordRequirements, samePassword]"
                    @keyup.enter="handleNewPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  color="secondary"
                  @click="handleNewPassword"
                  :disabled="!validReset"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </v-container>
  </div>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
export default {
  name: "ResetPassword",
  metaInfo() {
    return {
      // title will be injected into parent titleTemplate
      title: "Reset Password",
      meta: [
        {
          name: "description",
          content: "Reset your Password on " + this.$applicationName,
        },
      ],
    };
  },
  data() {
    return {
      validReset: false,
      reset: {
        loading: false,
        password: "",
        password2: "",
      },
    };
  },
  computed: {
    cardStyle: function() {
      if (this.$vuetify.breakpoint.width > 600) {
        return "margin-top: -10vh; width: 400px; margin-bottom: 5vh;";
      } else if (
        this.$vuetify.breakpoint.width <= 600 &&
        this.$vuetify.breakpoint.width > 350
      ) {
        return "margin-top: -20vh; width: 300px; margin-bottom: 5vh;";
      }
      return "margin-top: -10vh; width: 200px; margin-bottom: 5vh;";
    },
  },
  methods: {
    //METHODS
    handleNewPassword: async function() {
      await supabase.auth.api.updateUser(this.$route.query.token, {
        password: this.reset.password,
      });
      this.$router.push({ name: "Create" });
    },

    //RULES
    notEmpty: function(input) {
      if (input && input.length > 0) {
        return true;
      }
      return "This field is required!";
    },
    minPasswordRequirements: function(input) {
      let reUpperCase = /^(?=.*[A-Z])/;
      let reLowerCase = /^(?=.*[a-z])/;
      let reNumber = /^(?=.*\d)/;
      let reSpecial = /^(?=.*[-+_!@#$%^&*.,?])/;
      let reLength = /^.{8,}$/;
      let upperCase = reUpperCase.test(input);
      let lowerCase = reLowerCase.test(input);
      let number = reNumber.test(input);
      let special = reSpecial.test(input);
      let length = reLength.test(input);

      if (upperCase && lowerCase && number && special && length) {
        return true;
      } else {
        let errorString = "Minimum Requirements: ";

        if (!upperCase) {
          errorString = errorString + "1 Uppercase, ";
        }
        if (!lowerCase) {
          errorString = errorString + "1 Lowercase, ";
        }
        if (!number) {
          errorString = errorString + "1 Number, ";
        }
        if (!special) {
          errorString = errorString + "1 Special Character (-+_!@#$%^&*.,?), ";
        }
        if (!length) {
          errorString = errorString + "8 Characters in Total";
        }
        if (errorString.slice(-2) === ", ") {
          errorString = errorString.slice(0, -2);
        }
        return errorString;
      }
    },
    samePassword: function(input) {
      if (input === this.reset.password) {
        return true;
      }
      return "Passwords don't match!";
    },
  },
};
</script>
<style scoped>
.heading4 {
  font-size: 34px;
  font-weight: bold;
}
.center {
  height: 80vh;
  display: grid;
  place-items: center;
}
</style>
