<template>
  <v-app>
    <v-main id="app">
      <navBar />
      <router-view :class="responsiveMargin" />
      <notify />
    </v-main>
  </v-app>
</template>

<script>
import navBar from "./components/navBar";
import notify from "./components/notification";
export default {
  name: "App",
  components: {
    notify,
    navBar,
  },
  computed: {
    responsiveMargin: function() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "mt-16";
        case "sm":
          return "mt-16";
        case "md":
          return "ml-16 mt-16";
        case "lg":
          return "ml-16 mt-16";
        case "xl":
          return "ml-16 mt-16";
        default:
          return "ml-16 mt-16";
      }
    },
  },
};
</script>

<style>
#app {
  background-color: var(--v-background-base);
}

.theme--dark.v-list {
  background: var(--v-primary-base);
}
::v-deep .v-toolbar__content {
  padding: 0px !important;
}
</style>
