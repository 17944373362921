<template>
  <div>
    <v-row class="mt-10">
      <v-col class="d-flex justify-center">
        <h1>Auflösen einer Beschriftung</h1>
      </v-col>
    </v-row>
    <!-- DESKTOP-View -->
    <v-row class="hidden-sm-and-down">
      <v-col class="d-flex justify-center align-center">
        <v-text-field
          label="Aufzulösende Beschriftung"
          v-model="label"
          color="secondary"
        >
        </v-text-field>
      </v-col>
      <v-col class="d-flex justify-center align-center">
        <v-btn
          color="secondary"
          @click="resolveLabel"
          :loading="loadingResolve"
          fab
        >
          <v-icon>mdi-arrow-right-bold</v-icon>
        </v-btn>
      </v-col>
      <v-col>
        <v-container>
          <v-row>
            <v-col class="d-flex justify-center">
              <h2>Bedeutung der Beschriftung</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">Standort</v-col>
            <v-col class="d-flex justify-start secondary--text">
              {{ resolvedName.location }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">Raum</v-col>
            <v-col class="d-flex justify-start secondary--text">
              {{ resolvedName.room }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">Schrank Nr.</v-col>
            <v-col class="d-flex justify-start secondary--text">
              {{ resolvedName.shelfNumber }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">Art</v-col>
            <v-col class="d-flex justify-start secondary--text">
              {{ resolvedName.type }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">Art Nr.</v-col>
            <v-col class="d-flex justify-start secondary--text">
              {{ resolvedName.typeNumber }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex justify-end">Port 1</v-col>
            <v-col class="d-flex justify-start secondary--text">
              {{ resolvedName.port1 }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-end">Port 2</v-col>
            <v-col class="d-flex justify-start secondary--text">
              {{ resolvedName.port2 }}
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <!-- MOBILE-View -->
    <div class="hidden-md-and-up">
      <v-row>
        <v-col class="d-flex justify-center align-center px-10">
          <v-text-field
            label="Aufzulösende Beschriftung"
            v-model="label"
            color="secondary"
            @keyup.enter="resolveLabel"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <v-btn
            color="secondary"
            @click="resolveLabel"
            :loading="loadingResolve"
            fab
          >
            <v-icon>mdi-arrow-down-bold</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container>
            <v-row>
              <v-col class="d-flex justify-center">
                <h2>Bedeutung der Beschriftung</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">Standort</v-col>
              <v-col class="d-flex justify-start secondary--text">
                {{ resolvedName.location }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">Raum</v-col>
              <v-col class="d-flex justify-start secondary--text">
                {{ resolvedName.room }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">Schrank Nr.</v-col>
              <v-col class="d-flex justify-start secondary--text">
                {{ resolvedName.shelfNumber }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">Art</v-col>
              <v-col class="d-flex justify-start secondary--text">
                {{ resolvedName.type }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">Art Nr.</v-col>
              <v-col class="d-flex justify-start secondary--text">
                {{ resolvedName.typeNumber }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="d-flex justify-end">Port 1</v-col>
              <v-col class="d-flex justify-start secondary--text">
                {{ resolvedName.port1 }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end">Port 2</v-col>
              <v-col class="d-flex justify-start secondary--text">
                {{ resolvedName.port2 }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { getLocations, getRooms, getTypes } from "@/plugins/vuetify/helpers.js";
export default {
  name: "Resolve",
  data() {
    return {
      label: "",
      resolvedName: {
        location: "",
        room: "",
        shelfNumber: "",
        type: "",
        typeNumber: "",
        port1: "",
        port2: "",
      },
      loadingResolve: false,
    };
  },
  methods: {
    resolveLabel: async function() {
      this.resolvedName = {
        location: "",
        room: "",
        shelfNumber: "",
        type: "",
        typeNumber: "",
        port1: "",
        port2: "",
      };
      this.loadingResolve = true;
      let locations = await getLocations();
      let rooms = await getRooms();
      let types = await getTypes();

      //split label into parts divided by "-"
      let labelParts = this.label.split("-");

      let locationShort = labelParts[0];
      let typeShort = labelParts[1];
      let roomShort = labelParts[2];

      //get location alias from location short
      this.resolvedName.location = locations.find(
        (location) => location.short === locationShort
      ).alias;

      //get location id
      let locationId = locations.find(
        (location) => location.short === locationShort
      ).id;

      //get type long from short
      this.resolvedName.type = types.find(
        (type) => type.short === typeShort
      ).long;

      //get all rooms from rooms where location_id is equal to locationId
      let roomsFromLocation = rooms.filter(
        (room) => room.location_id === locationId
      );

      //get room long from short
      this.resolvedName.room = roomsFromLocation.find(
        (room) => room.short === roomShort
      ).long;

      //get type requirements from short
      let typeRequirements = types.find((type) => type.short === typeShort)
        .requirements;

      console.log(typeRequirements);
      console.log(typeShort);

      if (typeShort == "DDO") {
        console.log(labelParts);
        console.log(labelParts[labelParts.length - 1]);
        if (labelParts[labelParts.length - 1].includes("/")) {
          console.log("contains /");
          let portParts = labelParts[labelParts.length - 1].split("/");
          this.resolvedName.port1 = portParts[0];
          this.resolvedName.port2 = portParts[1];
        } else {
          this.resolvedName.port1 = labelParts[labelParts.length - 1];
        }
        this.resolvedName.shelfNumber = labelParts[2];
        this.resolvedName.typeNumber = labelParts[3];
      } else {
        if (typeRequirements.port1 && typeRequirements.port2) {
          this.resolvedName.port1 = labelParts[labelParts.length - 2];
          this.resolvedName.port2 = labelParts[labelParts.length - 1];
        } else if (typeRequirements.port1) {
          this.resolvedName.port1 = labelParts[labelParts.length - 1];
        }

        if (typeRequirements.typeNumber) {
          this.resolvedName.typeNumber = labelParts[4];
          if (typeRequirements.shelfNumber) {
            this.resolvedName.shelfNumber = labelParts[3];
          }
        } else if (typeRequirements.shelfNumber) {
          this.resolvedName.shelfNumber = labelParts[3];
        }
      }

      this.loadingResolve = false;
    },
  },
};
</script>
<style scoped></style>
