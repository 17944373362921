<template>
  <v-expansion-panels class="background px-4">
    <v-expansion-panel class="primary">
      <v-expansion-panel-header class="font-weight-bold">
        Standort erstellen
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-form v-model="validLocation" ref="locationForm">
            <v-col cols="10">
              <v-row>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Kürzel"
                    v-model="location.short"
                    color="secondary"
                    :rules="[shortExactlyTwoChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Beschreibung"
                    v-model="location.alias"
                    color="secondary"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="PLZ"
                    v-model="location.postalCode"
                    color="secondary"
                    type="number"
                    :rules="[postalCodeExactlyFiveChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Stadt"
                    v-model="location.city"
                    color="secondary"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Straße"
                    v-model="location.street"
                    color="secondary"
                    :rules="[notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Nummer"
                    v-model="location.number"
                    color="secondary"
                    type="number"
                    :rules="[notEmpty]"
                    @keyup.enter="saveLocation"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
          <v-col class="d-flex align-center">
            <v-btn
              color="secondary"
              @click="saveLocation"
              :disabled="!validLocation"
              >Speichern</v-btn
            >
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel class="primary">
      <v-expansion-panel-header class="font-weight-bold">
        Raum erstellen
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="8">
            <v-form v-model="validRoom" ref="roomForm">
              <v-row>
                <v-col class="d-flex align-center">
                  <v-autocomplete
                    label="Standort"
                    v-model="room.locationId"
                    item-value="id"
                    item-text="alias"
                    :items="locations"
                    color="secondary"
                    item-color="secondary"
                    :rules="[notEmpty]"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Kürzel"
                    v-model="room.short"
                    color="secondary"
                    :rules="[shortExactlyThreeChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Beschreibung"
                    v-model="room.long"
                    color="secondary"
                    :rules="[notEmpty]"
                    @keyup.enter="saveRoom"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col class="d-flex align-center">
            <v-btn color="secondary" @click="saveRoom" :disabled="!validRoom"
              >Speichern</v-btn
            >
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel class="primary">
      <v-expansion-panel-header class="font-weight-bold">
        Art erstellen
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
          <v-col cols="8">
            <v-form v-model="validType" ref="typeForm">
              <v-row>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Kürzel"
                    v-model="type.short"
                    color="secondary"
                    :rules="[shortExactlyThreeChars, notEmpty]"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    label="Beschreibung"
                    v-model="type.long"
                    color="secondary"
                    :rules="[notEmpty]"
                    @keyup.enter="saveType"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col>
                  Notwendige Angaben
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox
                    :label="type.long ? type.long + ' (Art. Nr)' : 'Art. Nr'"
                    color="secondary"
                    v-model="type.requirements.typeNumber"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    label="Schrank Nr."
                    color="secondary"
                    v-model="type.requirements.shelfNumber"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    label="Port 1"
                    color="secondary"
                    v-model="type.requirements.port1"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    label="Port 2"
                    color="secondary"
                    v-model="type.requirements.port2"
                    :disabled="!type.requirements.port1"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
          <v-col class="d-flex align-center">
            <v-btn color="secondary" @click="saveType" :disabled="!validType"
              >Speichern</v-btn
            >
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { supabase } from "@/plugins/supabase/supabase.js";
import { getLocations, getRooms, getTypes } from "@/plugins/vuetify/helpers.js";
export default {
  name: "createDataPanels",
  data() {
    return {
      location: {
        short: null,
        alias: null,
        postalCode: null,
        city: null,
        street: null,
        number: null,
      },
      room: {
        short: null,
        long: null,
        locationId: null,
      },
      type: {
        short: null,
        long: null,
        requirements: {
          typeNumber: false,
          shelfNumber: false,
          port1: false,
          port2: false,
        },
      },
      locations: [],
      rooms: [],
      types: [],
      validLocation: false,
      validRoom: false,
      validType: false,
    };
  },
  mounted() {
    if (this.$route.query.message) {
      let notify = {
        duration: 4000,
        type: "info",
        headline: this.$route.query.message,
        message: "",
      };
      this.$globalState.addNotification(notify);
    }
    this.getLocations();
  },
  methods: {
    getLocations: async function() {
      this.locations = await getLocations();
    },
    getRooms: async function() {
      this.rooms = await getRooms();
    },
    getTypes: async function() {
      this.types = await getTypes();
    },
    saveLocation: async function() {
      let locations = await getLocations();
      console.log(locations);
      // if property short of a item in locations is the same as location.short return false else return true
      if (!locations.find((item) => item.short === this.location.short)) {
        let response = await supabase.from("location").insert({
          short: this.location.short,
          alias: this.location.alias,
          city: this.location.city,
          postal_code: this.location.postalCode,
          street: this.location.street,
          number: this.location.number,
          created: Date.now(),
          updated: Date.now(),
        });
        if (response.status === 201) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Standort erfolgreich gespeichert",
          };
          this.$globalState.addNotification(notify);
          this.getLocations();
          this.$refs.locationForm.reset();
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message:
              "Fehler beim Speichern des Standortes (" + response.status + ")",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        this.$globalState.addNotification({
          duration: 4000,
          type: "error",
          headline: "Fehler",
          message: "Standort mit diesem Kürzel existiert bereits",
        });
      }
    },
    saveRoom: async function() {
      let rooms = await getRooms();
      if (
        !rooms.find(
          (item) =>
            item.short === this.room.short &&
            item.location_id === this.room.locationId
        )
      ) {
        let response = await supabase.from("room").insert({
          short: this.room.short,
          long: this.room.long,
          location_id: this.room.locationId,
          created: Date.now(),
          updated: Date.now(),
        });
        if (response.status === 201) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Raum erfolgreich gespeichert",
          };
          this.$globalState.addNotification(notify);
          this.getRooms();
          this.$refs.roomForm.reset();
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message:
              "Fehler beim Speichern des Raumes (" + response.status + ")",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        this.$globalState.addNotification({
          duration: 4000,
          type: "error",
          headline: "Fehler",
          message: "Raum mit diesem Kürzel existiert bereits",
        });
      }
    },
    saveType: async function() {
      let types = await getTypes();
      if (!types.find((item) => item.short === this.type.short)) {
        let response = await supabase.from("type").insert({
          short: this.type.short,
          long: this.type.long,
          requirements: this.type.requirements,
          created: Date.now(),
          updated: Date.now(),
        });
        console.log(response);
        if (response.status === 201) {
          let notify = {
            duration: 4000,
            type: "success",
            headline: "",
            message: "Art erfolgreich gespeichert",
          };
          this.$globalState.addNotification(notify);
          this.getTypes();
          this.$refs.typeForm.reset();
        } else {
          let notify = {
            duration: 4000,
            type: "error",
            headline: "",
            message: "Fehler beim Speichern der Art (" + response.status + ")",
          };
          this.$globalState.addNotification(notify);
        }
      } else {
        this.$globalState.addNotification({
          duration: 4000,
          type: "error",
          headline: "Fehler",
          message: "Art mit diesem Kürzel existiert bereits",
        });
      }
    },

    //RULES
    shortExactlyTwoChars: function(value) {
      if (value && value.length === 2) {
        return true;
      } else {
        return "Das Kürzel muss 2 Zeichen lang sein";
      }
    },
    shortExactlyThreeChars: function(value) {
      if (value && value.length === 3) {
        return true;
      } else {
        return "Das Kürzel muss 3 Zeichen lang sein";
      }
    },
    postalCodeExactlyFiveChars: function(value) {
      if (value && value.length === 5) {
        return true;
      } else {
        return "Die Postleitzahl muss 5 Zeichen lang sein";
      }
    },
    notEmpty: function(value) {
      if (value && value.length > 0) {
        return true;
      } else {
        return "Dieses Feld darf nicht leer sein";
      }
    },
  },
};
</script>
<style scoped></style>
