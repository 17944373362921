<template>
  <v-row>
    <v-col
      class="hidden-sm-and-down d-flex justify-end align-start pr-0"
      :cols="colSpacing.left"
    >
      <v-btn
        v-if="showHash"
        icon
        class="mr-n5 mt-n1"
        :to="link"
        @mouseenter="showHash = true"
        @mouseleave="showHash = false"
      >
        <v-icon small color="secondary">mdi-pound</v-icon>
      </v-btn>
    </v-col>
    <v-col :cols="colSpacing.mid" :class="typography.body">
      <span
        @click="$router.push({ path: link })"
        :class="typography[typographyType]"
        :id="id"
        @mouseenter="showHash = true"
        @mouseleave="showHash = false"
        >{{ headline }}</span
      >
    </v-col>
    <v-col class="hidden-sm-and-down" :cols="colSpacing.left"></v-col>
  </v-row>
</template>
<script>
export default {
  name: "heading",
  props: {
    colSpacing: Object,
    typography: Object,
    headline: String,
    link: String,
    id: String,
    typographyType: String,
  },
  data() {
    return {
      showHash: false,
    };
  },
};
</script>
<style scoped></style>
